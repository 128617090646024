import React from 'react';
import { Link } from 'react-router-dom';
import contactus_button from '../assets/contactus_button.png' 

const STYLES = ['btn--contactus--black', 'btn--contactus--blue', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

const ContactusButton = ({ children, type, onClick, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <Link to="/contactus" className="btn-mobile">
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                // eslint-disable-next-line react/button-has-type
                type={type}
            >
                <img src={contactus_button} className='btn--contactus--image' alt="" srcset="" />
                <p>{children}</p>
            </button>
        </Link>
    );
};

export default ContactusButton;
