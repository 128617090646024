import React from 'react'
import ContactusButton from '../components/ContactusButton'
import InfoFooter from './InfoFooter'
import footerImg from '../assets/footer-img.png'

export default function Footer() {
    return (
        <footer className='footer'>
            <div className='footer-container'>
                <div className='footer-container-left'>
                    <h6>Get in touch</h6>
                    <h1>Connect with us for a <span className='blue-highlight'>free consultation</span></h1>
                    <p className='footer-container-left-desc'>Want to know how Massive Technologies can help you scale and grow your business?</p>
                    <ContactusButton buttonStyle="btn--contactus--blue">Contact Us</ContactusButton>
                </div>
                <div className='footer-container-right'>
                    <img src={footerImg} alt="" />
                </div>
            </div>
            <InfoFooter/>
        </footer>
    )
}
