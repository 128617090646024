import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Portfolio from "./pages/Portfolio";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import "./styles/styles.scss"


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";


function App() {

return(
      <>
      <ScrollToTop />
      <Routes>
            <Route path="/about" element={<About />}/>
            
            <Route path="/portfolio" element={<Portfolio />}/>
           
            <Route path="/contactus" element={<ContactUs />}/>
           
            <Route path="/" element={<Home />}/>
            
      </Routes >
      </>

 
);

}

export default App;
