import React from 'react'
import ContactusButton from '../components/ContactusButton'
import footerImg from '../assets/footer-img.png'

export default function InfoFooter() {
    return (
        
            <div className='footer-bottom'>
                <div className='footer-bottom-left'>
                    <h5>Developed By <span>Massive Technologies Pvt Ltd</span></h5>
                </div>
                <div className='footer-bottom-right'>
                    <h5>Business illustrations by <span>Storyset</span></h5>
                    <h5>Info icons created by <span>Freepik - Flaticon</span></h5>
                </div>
            </div>

    )
}
