import React from 'react'
import { Fade } from "react-awesome-reveal";

export default function ServiceCard({ img, heading, content }) {
  return (
    <div className='service-card'>
      <Fade>
        <img src={img} alt="" />
     
      <h5>{heading}</h5>
      <p>{content}</p>
      </Fade>
    </div>
  )
}
