import React from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from "react-icons/md";

const STYLES = ['btn--contactus', 'btn--outline', 'btn--test', 'btn--viewportfolio--black', 'btn--viewportfolio--outline'];

const SIZES = ['btn--medium', 'btn--large'];

const ViewPortfolioButton = ({ children, type, onClick, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <Link to="/portfolio" className="btn-mobile">
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                // eslint-disable-next-line react/button-has-type
                type={type}
            >
                <p>{children}</p>
                <MdKeyboardArrowRight fontSize={25} />
            </button>
        </Link>
    );
};

export default ViewPortfolioButton;
