import React from 'react';
import { Link } from 'react-router-dom';
import phone from '../assets/phone.png'
import phoneWhite from '../assets/phone-white.png'

const STYLES = ['btn--contactus', 'btn--outline', "btn--callbutton--black", 'btn--callbutton--outline'];

const SIZES = ['btn--medium', 'btn--large'];

const CallButton = ({ children, type, onClick, buttonStyle, buttonSize }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    const blackButton = buttonStyle.includes('black');
    return (
        <Link to="/contactus" className="btn-mobile">
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                onClick={onClick}
                // eslint-disable-next-line react/button-has-type
                type={type}
            >
                {
                    blackButton ?
                        <img src={phoneWhite} className='btn--outline--image' alt="" srcset="" /> :
                        <img src={phone} className='btn--outline--image' alt="" srcset="" />
                }
                <p>{children}</p>
            </button>
        </Link>
    );
};

export default CallButton;
