import React, {useState} from 'react'
import ContactusButton from '../components/ContactusButton'
import footerImg from '../assets/footer-img.png'
import emailImg from '../assets/mail.png'
import phoneImg from '../assets/phone.png'
import InfoFooter from './InfoFooter'


export default function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");


    return (
        <div class="contact">
            
            <div class="contact-title">Let's Get In Touch</div>
            <div class="contact-description">Please leave your message below, We'll get the right person to get in touch with you.</div>

            <div class="details-holder">
            <div class="contact-info">
                    <img src={emailImg}/>
                    <span><a class="mailLink" href="mailto:sales@massivetechnologies.in"><p>Email : sales@massivetechnologies.in </p></a></span>
                </div>
                <div class="contact-info">
                    <img src={phoneImg}/>
                    <span><p onclick="window.open('tel:+919131649265');">Phone : +91-9131649265 </p></span>
                </div>
            </div>

            <div class="contact-form-container">
                <div class="contact-form">
                    <form method="post">
                    <div class="form-field">
                            <label>
                                Your Name : 
                            </label>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder='Your Name' name="name"/>
                        </div>
                        <div class="form-field">
                            <label>
                                Your Email Address : 
                            </label>
                            <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='Your Email Address (so we can contact you later)' name="email"/>
                        </div>
                        <div class="form-field">
                            <label>
                                Your Phone (Optional): 
                            </label>
                            <input type="text" value={phone} onChange={e => setPhone(e.target.value)} placeholder='Your Phone Numer (Optional, but preffered)' name="phone"/>
                        </div>
                        <div class="form-field">
                            <label>
                                Your Message (Tell us about your cool ideas and your vision, we are all ears) : 
                            </label>
                            <textarea type="text" placeholder='Your message here' value={message} onChange={e => setMessage(e.target.value)} name="message"/>
                        </div>
                        <div class="btn-holder">
                        <input type="submit" value="Send Message"/>
                        </div>
                    </form>
                </div>
            </div>
            <div class="footer">
            <InfoFooter/>
            </div>
            
            
            
        </div>
    )
}
