import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContactusButton from '../components/ContactusButton';
import massive_logo from '../assets/massive_logo.png'
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={massive_logo} alt="" className='navbar-logo-icon' />
                        <p className='navbar-logo-text'>
                            <span>Massive</span>
                            <span>Technologies</span>
                        </p>
                    </Link>
                    <div className="menu-icon" role="button" tabIndex="0" onClick={handleClick}>
                        {
                            click ?
                                <IoClose className="menu-icon" /> :

                                <GiHamburgerMenu className="menu-icon" />
                        }
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li id='nav-title' className="nav-item">
                            <h4 className=''>
                                Quick <span className='highlight'>Links</span>
                            </h4>
                        </li>
                        <li className="nav-item">
                            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/portfolio" className="nav-links" onClick={closeMobileMenu}>
                                Portfolio
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/products" className="nav-links" onClick={closeMobileMenu}>
                                Services
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                                About Us
                            </Link>
                        </li>
                        <li id="contactus-mobile" className="nav-item">
                            <Link to="/contactus" className="nav-links" onClick={closeMobileMenu}>
                                Contact Us
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="/contactus"
                                className="nav-links-mobile"
                                onClick={closeMobileMenu}
                            >
                                Sign Up
                            </Link>
                        </li>
                    </ul>
                    {button && <ContactusButton buttonStyle="btn--contactus--black">Contact Us</ContactusButton>}
                </div>
            </nav>
        </>
    );
}

export default Navbar;
