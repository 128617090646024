import Footer from "../layout/Footer";
import IntroSection from "../layout/IntroSection";
import Navbar from "../layout/Navbar";
import ServicesTechnology from "../layout/ServicesTechnology";
import Testimony from "../layout/Testimony";
import WhySection from "../layout/WhySection";



function Home() {


  return (
    <>
      
      <Navbar/>
      <IntroSection/>
      <WhySection/>
      <ServicesTechnology/>
      {/* <Testimony/> */}
      <Footer />

    </>
  );
}

export default Home;
