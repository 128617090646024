import React from 'react'
import ContactusButton from '../components/ContactusButton'
import footerImg from '../assets/footer-img.png'
import emailImg from '../assets/mail.png'
import phoneImg from '../assets/phone.png'
import InfoFooter from './InfoFooter'
import jkalcoholImg from '../assets/portfolio/jkalcohol.png'
import cleanzoneImg from '../assets/portfolio/cleanzone.png'
import kwiperscloudImg from '../assets/portfolio/kwiperscloud.png'
import mbaImg from '../assets/portfolio/mba.png'
import rentalImg from '../assets/portfolio/rental.png'
import shivtradersImg from '../assets/portfolio/shiv-traders.png'


export default function PortfolioPage() {
    return (
        <div class="portfolio">
            <div class="portfolio-title">
                Portfolio
            </div>
            <ul class="portfolio-container">
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={jkalcoholImg}/></div>
                    <div class="portfolio-card-title">JK Enterprises</div>
                    <div class="portfolio-card-description">JK Alcohol is the leading Alcohol manufacturer in India and they have tapped into the Indian market with their high-quality liquor products, including rum, whisky, gin, and vodka.</div>
                </li>
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={shivtradersImg}/></div>
                    <div class="portfolio-card-title">ShivTraders</div>
                    <div class="portfolio-card-description">ShivTradere are the leading hardware wholesaler in Madhya Pradesh, their goal is to provide a reliable and efficient supply chain solution for hardware products.</div>
                </li>
                
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={cleanzoneImg}/></div>
                    <div class="portfolio-card-title">CleanZone</div>
                    <div class="portfolio-card-description">Clean Zone Services is a nationally recognized independent agency approved by ISO 9001:2008 delivering quality services in the pharmaceutical and healthcare sector.</div>
                </li>
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={kwiperscloudImg}/></div>
                    <div class="portfolio-card-title">Kwipers Cloud</div>
                    <div class="portfolio-card-description">Kwipers cloud is a one stop solution for your cloud storage, computing and hosting needs.</div>
                </li>
                
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={mbaImg}/></div>
                    <div class="portfolio-card-title">TrignoGreen</div>
                    <div class="portfolio-card-description"> loT-based startup, working on energy management solutions, the startup is led by IIM-K Aluminis.</div>
                </li>
                <li class="portfolio-card">
                    <div class="portfolio-imageConatiner"><img class="portfolio-card-image" src={rentalImg}/></div>
                    <div class="portfolio-card-title">RentalApp</div>
                    <div class="portfolio-card-description">Rental app is the product for the property owners, focussing in managing the rental collection from the tenants.</div>
                </li>
                

                
            </ul>
            
        </div>
    )
}
