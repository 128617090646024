import Footer from "../layout/Footer";
import IntroSection from "../layout/IntroSection";
import Navbar from "../layout/Navbar";
import ServicesTechnology from "../layout/ServicesTechnology";
import Testimony from "../layout/Testimony";
import WhySection from "../layout/WhySection";
import ContactForm from "../layout/ContactForm";


function ContactUs() {

  return (
    <>
      <Navbar/>
      <ContactForm/>
    </>
  );
}

export default ContactUs;
