import Footer from "../layout/Footer";
import IntroSection from "../layout/IntroSection";
import Navbar from "../layout/Navbar";
import ServicesTechnology from "../layout/ServicesTechnology";
import Testimony from "../layout/Testimony";
import WhySection from "../layout/WhySection";
import ViewPortfolioButton from '../components/ViewPortfolioButton'
import { Fade,Slide,Bounce } from "react-awesome-reveal";


function About() {

  return (
    <>
      <Navbar/>
      
      <div class="aboutus">
        <div class="aboutus-title">
          It's not who we are,<br></br>But what we do that defines us
        </div>
      
        <div class="aboutus-description">
        At Massive Technologies, we are fervent believers in the transformative power of technology. As a pioneering web and app development company, we are committed to pushing the boundaries of innovation to empower businesses to thrive in the digital age.
        </div>
        <div class="aboutus-description">
        <strong>Driven by a passion for cutting-edge technologies</strong>, our team of tech enthusiasts brings together expertise in diverse disciplines to craft bespoke solutions tailored to meet the unique needs of each client. With a focus on innovation, creativity, and precision, we partner with businesses of all sizes to digitalize their operations, streamline processes, and unlock new opportunities for growth.
        <br/>
        <br/>
        What sets us apart is our unwavering dedication to excellence and our relentless pursuit of perfection in every project we undertake. From conceptualization to execution, we work closely with our clients to understand their vision, goals, and challenges, leveraging the latest tools and methodologies to deliver solutions that exceed expectations.
        <br/>
        <br/>
        At Massive Technologies, we don't just build applications and websites – we build experiences that inspire, engage, and drive results. Whether you're a <strong>startup looking to make your mark or an established enterprise aiming to stay ahead of the curve</strong>, we're here to be your trusted partner on the journey to digital success.
        <br/>
        <br/>
        Join us as we <strong>harness the power of technology</strong> to shape a brighter, more connected future for businesses around the globe.
        </div>
        <div class="aboutus-btn-container">
        <ViewPortfolioButton buttonStyle='btn--viewportfolio--outline'>View Our Portfolio</ViewPortfolioButton>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default About;
