import React from 'react'
import card1Img from '../assets/card-1-img.png'
import card2Img from '../assets/card-2-img.png'
import card3Img from '../assets/card-3-img.png'
import CallButton from '../components/CallButton'
import { Fade,Slide,JackInTheBox } from "react-awesome-reveal";

export default function WhySection() {
    return (
        <section className='whySection--container'>
            <div className='whySection--titleContainer'>
                <Fade>
                <h2>Cultivating Your Digital Success</h2>
                <h1>Why your business needs a <span className='highlight'>Website/App</span> !</h1>
                </Fade>
            </div>
            <div className='whySection--cardContainer'>
                <div className='whySection--cardContainer--left'>
                    
                    <div className='card1'>
                        <h3>Automate your process</h3>
                        <p>Streamline your business operations with custom-built software solutions. From workflow automation to data management, we empower your team to work smarter, not harder.</p>
                        <img src={card1Img} alt="" />
                    </div>
                    
                </div>
                <div className='whySection--cardContainer--right'>
                    <div>
                    <Fade>
                        <div className='card2'>
                            <h3>Get global reach</h3>
                            <div className='card2--container'>
                                <p>Expand your market reach with our innovative app and web development services. Reach customers worldwide and grow your business exponentially with our tailored digital solutions.</p>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={card2Img} alt="" />
                                </div>
                            </div>
                        </div>
                        </Fade>
                    </div>
                    <div>
                    <Fade>
                        <div className='card3'>
                            <h3>Create an online presence for you brand</h3>
                            <div className='card2--container'>
                                <p>Stand out in the digital landscape with a captivating online presence. Our expert team crafts engaging websites and user-friendly apps to elevate your brand and attract customers.</p>
                                <img src={card3Img} alt="" />
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className='get-call-container'>
                <div className='get-call-container-text'>
                    <p>
                        Want to know how Massive Technologies can help you scale and grow your business? Get a free consultation for our team
                    </p>
                </div>
                <div className='get-call-container-btn'>
                    <CallButton buttonStyle="btn--callbutton--black">Get a Free Consultation Call</CallButton>
                </div>
            </div>
        </section>
    )
}
