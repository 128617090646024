import React, { useEffect, useState } from 'react';
import massive_logo_transparent from "../assets/massive_logo_transparent.png"
import section1_img from "../assets/section1_img.png"
import ViewPortfolioButton from '../components/ViewPortfolioButton'
import CallButton from '../components/CallButton';
import { Fade,Slide,Bounce } from "react-awesome-reveal";

export default function IntroSection() {
    const [button, setButton] = useState(true);

    const showButton = () => {
        console.log(window.innerWidth);
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <section className='intro'>
            <div className='intro--container'>
                <div className='intro--container--left'>
                    <div>
                        <img src={massive_logo_transparent} className="intro--container--left--bg" alt="" srcset="" />
                    </div>
                    
                    <div className='intro--container--left--content'>
                    <Fade>
                        <span>Scale Your</span>
                        <span>Business</span>
                        <span>With</span>
                        <span className='highlight'>Technology</span>
                        <div className='intro--container--left--buttons'>
                            {button && <ViewPortfolioButton buttonStyle="btn--viewportfolio--black">View Our Portfolio</ViewPortfolioButton>}
                            <CallButton buttonStyle="btn--callbutton--outline">Free Consultation Call</CallButton>
                        </div>
                        </Fade>
                    </div>
                </div>
                <div className='intro--container--right'>
                    <Bounce>
                        <img src={section1_img} className='intro--container--right--image' alt="" />
                    </Bounce>
                </div>
            </div>
            <div className='intro--products'>
                <Fade>
                <p className='intro--products--text'>
                    Website · Android/IOS Application · UI/UX · Logo
                </p>
                </Fade>
            </div>
        </section>
    )
}
