import React from 'react'
import ServiceCard from '../components/ServiceCard'
import ViewPortfolioButton from '../components/ViewPortfolioButton'
import AboutusButton from '../components/AboutusButton'
import { Fade } from "react-awesome-reveal";

export default function ServicesTechnology() {
  return (
    <section className='services'>
      <div className='services-headings'>
        <Fade duration={2000}>
        <h5>Ask us about our tech</h5>
        <h1>Our Services & Technologies</h1>
        <p>Unlock the potential of online presence and tap into limitless opportunities for growth and success</p>
        </Fade>
      </div>
      <div className='services-container'>
        <ServiceCard img={require('../assets/www.png')} heading={"Website Development"} content={"Crafting visually stunning and functional websites tailored to your brand's unique identity and goals"} />
        <ServiceCard img={require('../assets/mobile-app.png')} heading={"Application Development"} content={"Building cutting-edge mobile and web applications to bring your ideas to life and enhance user experiences"} />
        <ServiceCard img={require('../assets/uiux.png')} heading={"UI/UX Design"} content={"Creating intuitive and visually appealing interfaces that ensure seamless navigation and delightful user interactions"} />
        <ServiceCard img={require('../assets/logo-design.png')} heading={"Logo Design"} content={"Designing impactful logos that reflect your brand's personality and leave a lasting impression"} />
      </div>
      <div className='technologies'>
        <h2><span>Technologies we use</span></h2>
        <div className='technologies-container'>
          <Fade duration={1500}>
          <div><img src={require("../assets/react.png")} alt="" /></div>
          <div><img src={require("../assets/js.png")} alt="" /></div>
          <div><img src={require("../assets/php.png")} alt="" /></div>
          <div><img src={require("../assets/nodejs.png")} alt="" /></div>
          <div><img src={require("../assets/database.png")} alt="" /></div>
          </Fade>
        </div>
        <p className='technologies-bottom'>and more</p>
      </div>
      <div className='industries'>
        <h2><span>Industries we have catered</span></h2>
        <Fade duration={1500}>
        <div className='industries-container'>
          <h4>REAL ESTATE</h4>
          <h4>FINTECH</h4>
          <h4>INDUSTRIAL AUTOMATION</h4>
          <h4>TRAVEL</h4>
        </div>
        </Fade>
        <p className='industries-bottom'>and counting</p>
      </div>
      <div className='button-container'>
        <AboutusButton>Learn About Us</AboutusButton>
        <ViewPortfolioButton buttonStyle='btn--viewportfolio--outline'>View Our Portfolio</ViewPortfolioButton>
      </div>
    </section>
  )
}
